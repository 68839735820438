.popover-content {
  border-radius: 30px !important;
}
.emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
}
.emoji:after {
  position: absolute;
  bottom: -16px;
  font-size: 7.2px;
  width: 24px;
  left: calc(50% - 12px);
  color: #8a8a8a;
}

.emoji-like {
  display: inline-block;
  width: 50px;
  height: 3200px;
  background-image: url('src/assets/images/iconEmojiSocial/likechuan.svg');
  background-size: 50px 3200px;
  will-change: transform;
  transform: translate3d(0px, -3102px, 0px);
  transform-origin: left top;
  animation: 2.20833s steps(63) 0s infinite reverse none running
    SpriteComponentAnimation;
  backface-visibility: hidden;
}

.emoji-love {
  display: inline-block;
  width: 80px;
  height: 4800px;
  background-image: url('src/assets/images/iconEmojiSocial/love19.svg');
  background-size: 80px 4800px;
  will-change: transform;
  transform: translate3d(0px, -4720px, 0px);
  transform-origin: left top;
  animation: 3.20833s steps(59) 0s infinite normal none running
    SpriteComponentAnimation;
  backface-visibility: hidden;
}

.emoji-yay {
  display: inline-block;
  width: 90px;
  height: 10800px;
  background-image: url('src/assets/images/iconEmojiSocial/yay.svg');
  background-size: 90px 10800px;
  will-change: transform;
  transform: translate3d(0px, -10705px, 0px);
  transform-origin: left top;
  animation: 3.20833s steps(119) 0s infinite normal none running
    SpriteComponentAnimation;
  backface-visibility: hidden;
}

.emoji-haha {
  display: inline-block;
  width: 65px;
  height: 5850px;
  background-image: url('src/assets/images/iconEmojiSocial/haha13.svg');
  background-size: 65px 5849px;
  will-change: transform;
  transform: translate3d(0px, -5784px, 0px);
  transform-origin: left top;
  animation: 2.80833s steps(89) 0s infinite normal none running
    SpriteComponentAnimation;
  backface-visibility: hidden;
}

.emoji-wow {
  display: inline-block;
  width: 56px;
  height: 9352px;
  background-image: url('src/assets/images/iconEmojiSocial/wow.svg');
  background-size: 56px 9352px;
  will-change: transform;
  transform: translate3d(0px, -9296px, 0px);
  transform-origin: left top;
  animation: 3.20833s steps(166) 0s infinite normal none running
    SpriteComponentAnimation;
  backface-visibility: hidden;
}

.emoji-sad {
  display: inline-block;
  width: 56px;
  height: 6720px;
  background-image: url('src/assets/images/iconEmojiSocial/SAD.svg');
  background-size: 56px 6717px;
  will-change: transform;
  transform: translate3d(0px, -6688px, 0px);
  transform-origin: left top;
  animation: 3.20833s steps(119) 0s infinite normal none running
    SpriteComponentAnimation;
  backface-visibility: hidden;
}

.emoji-angry {
  display: inline-block;
  width: 52px;
  height: 6240px;
  background-image: url('src/assets/images/iconEmojiSocial/ANGRY.svg');
  background-size: 52px 6240px;
  will-change: transform;
  transform: translate3d(0px, -6210px, 0px);
  transform-origin: left top;
  animation: 3.20833s steps(119) 0s infinite normal none running
    SpriteComponentAnimation;
  backface-visibility: hidden;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-160px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
  }
}

.floating-icon-wrapper {
  position: fixed;
  bottom: 0px;
  right: 0px;
  animation: floatAnimation 1s ease-out;
}

/* .floating-icon-wrapper::after {
  content: '';
  position: absolute;
  bottom: -190px;
  left: 50%;
  width: 36px;
  height: 150px;
  background-color: red;
  border-radius: 30px;
  transform: translate(-50%, -50%);
  z-index: -1;
} */

@-webkit-keyframes SpriteComponentAnimation {
  0% {
    transform: translateZ(0);
  }
}
